import { Google, LockOutlined } from "@mui/icons-material";
import { Box, Avatar, Typography, Grid, TextField, Button, Link, IconButton } from "@mui/material";
import { handleGoogleLogin, handleRegister } from "../../scripts/login";

export default function Register({ setAuthScreen }: { setAuthScreen: React.Dispatch<React.SetStateAction<string>> },) {
    return <Box
        sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
            Registrarse
        </Typography>
        <Box component="form" noValidate onSubmit={(e) => { handleSubmit(e); setAuthScreen('login'); }} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="Nombre"
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        id="lastName"
                        label="Apellido"
                        name="lastName"
                        autoComplete="family-name"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                    />
                </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Registrarse
            </Button>
            <Grid container>
                <Grid item xs sx={{ alignSelf: 'center' }}>
                    <Link href="#" onClick={(e) => setAuthScreen('loginPass')} variant='body2'>
                        ¿Ya tenés una cuenta? Iniciar sesión
                    </Link>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={handleGoogleLogin}>
                        <Google />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    </Box>
}

const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')?.valueOf()
    const password = data.get('password')?.valueOf()
    const fullname = data.get('firstName')?.valueOf() + ' ' + data.get('lastName')?.valueOf()
    if (typeof (email) === 'string' && typeof (password) === 'string') return handleRegister(email, password, fullname)
};