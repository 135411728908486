import { Google, LockOutlined } from "@mui/icons-material"
import { Box, Avatar, Typography, TextField, Button, Grid, Link, IconButton } from "@mui/material"
import { handleGoogleLogin, handlePassSignIn } from "../../scripts/login"

export default function Login({ setAuthScreen, mode }: { setAuthScreen: React.Dispatch<React.SetStateAction<string>>, mode?: string | null },) {
    return <Box
        sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
            Iniciar sesión
        </Typography>
        <Box component="form" onSubmit={(e) => handleSubmit(e)} noValidate sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: '#a946c6' }}
            >
                Iniciar sesión
            </Button>
            {mode === null &&
                <Grid container>
                    <Grid item xs sx={{ alignSelf: 'center' }}>
                        <Link href="#" onClick={(e) => setAuthScreen('lostPass')} variant='body2'>
                            ¿Olvidaste la contraseña?
                        </Link>
                        <br />
                        {!['verifyEmail', 'recoverEmail'].includes(mode || '') &&
                            <Link href="#" onClick={(e) => setAuthScreen('register')} variant='body2'>
                                ¿No tienes cuenta? Registrarse
                            </Link>}
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={handleGoogleLogin}>
                            <Google />
                        </IconButton>
                    </Grid>
                </Grid>}
        </Box>
    </Box>
}

const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')?.valueOf()
    const password = data.get('password')?.valueOf()
    if (typeof (email) === 'string' && typeof (password) === 'string') return handlePassSignIn(email, password)
};