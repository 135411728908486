import { format } from 'date-fns';

export function formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) return '0 Bytes';
    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export function toTitleCase(e: string) { return e ? e.replace(/\w*/g, (txt) => { return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(); }) : '' }


export function formatToLocalTime(timestamp: string, admin: boolean) {
    const date = new Date(Number(timestamp));
    return format(date, admin ? 'HH:mm:ss dd-MM-yyyy' : 'dd-MM-yyyy');
};