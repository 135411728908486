import { LockOutlined } from "@mui/icons-material";
import { Box, Avatar, Typography, Grid, TextField, Button, Alert, AlertTitle } from "@mui/material";
// import { handleLinkUrl } from "../../scripts/login";
import firebase from "../../firebase";

export function ValidateEmail({ user }: { user: firebase.User | null }) {
    return <Box
        sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
            Verificar Email
        </Typography>
        <Box component="form" noValidate onSubmit={(e) => handleSubmit(e, user)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        disabled
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        value={user?.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="success">
                        <AlertTitle>Email enviado</AlertTitle>
                        Se ha enviado un email para<br />confirmar esta cuenta!
                    </Alert>
                </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                disabled
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Reenviar email de verificación
            </Button>
            {mappedEmailProvider(user?.email) &&
                <Button
                    type="submit"
                    fullWidth
                    // @ts-ignore
                    onClick={(e) => window.open(mappedEmailProvider(user?.email), '_blank')}
                    variant="contained"
                    sx={{ mt: 0, mb: 2 }}
                >
                    Chequear correo
                </Button>}
        </Box>
    </Box>
}

const handleSubmit = (event: React.FormEvent<HTMLFormElement>, user: firebase.User | null) => {
    event.preventDefault();
    user?.sendEmailVerification()
};

const mappedEmailProvider = (email: string | null | undefined) => {
    if (!email) return false
    switch (email.split('@')[-1]) {
        case 'google.com': return 'https://mail.google.com/mail/u/0/'
        case 'outlook.com': case 'hotmail.com': case 'live.com': return 'https://outlook.live.com/mail/0/'
        case 'yahoo.com': case 'yahoo.com.ar': return 'https://mail.yahoo.com/'
        default: return false
    }
}