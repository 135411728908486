import { Container, Paper, Typography } from "@mui/material";
import { useState } from "react";
import Login from "./AuthScreen/login";
import LostPass from "./AuthScreen/lostPass";
import Register from "./AuthScreen/register";
import firebase from "../firebase";
import { ValidateEmail } from "./AuthScreen/validateEmail";
import { ResetPass } from "./AuthScreen/resetPass";

const noSelect = {
    'userSelect': 'none',
    'WebkitUserSelect': 'none',
    'MozUserSelect': 'none',
    'KhtmlUserSelect': 'none',
    'msUserSelect': 'none',
}

export function AuthScreen({ user }: { user: firebase.User | null }) {
    const [authScreen, setAuthScreen] = useState('login');

    const searchParams = new URLSearchParams(document.location.search)
    const mode = searchParams.get('mode');
    const actionCode = searchParams.get('oobCode');

    switch (mode) {
        case 'recoverEmail': case 'verifyEmail':
            if (!user || !actionCode) break;
            firebase.auth().checkActionCode(actionCode).then(async () => {
                await firebase.auth().applyActionCode(actionCode);
                await user.reload()
                window.location.assign(window.location.origin)
            }).catch(() => { })
            break;
    }

    if (user && user.emailVerified) return <></>

    return (
        <Container component="main" maxWidth="xs" sx={noSelect}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                {mode === "resetPassword" && actionCode ? <ResetPass actionCode={actionCode} /> :
                    authScreen === 'register' ? <Register setAuthScreen={setAuthScreen} />
                        : authScreen === 'lostPass' ? <LostPass setAuthScreen={setAuthScreen} mode={mode} />
                            : user && !user.emailVerified ? <ValidateEmail user={user} />
                                : <Login setAuthScreen={setAuthScreen} mode={mode} />
                }
                <Typography variant="body2" sx={{ pt: 1, display: 'flex', justifyContent: 'flex-end', color: 'gray' }}>{process.env.REACT_APP_GIT_HASH && 'v. ' + process.env.REACT_APP_GIT_HASH}</Typography>
            </Paper>
        </Container>
    )
}

