// import { useState } from 'react';
import firebase from '../firebase';
// import { toTitleCase } from './helpers';
import { toast } from 'react-toastify';
import axios from 'axios';

interface Request {
    key: string;
    fileId: string;
    fileName: string;
    images: string[];
    emailSent: string[];
    timestamp: string;
    preloaded: boolean;
}

export const updateTableView = (async (user: firebase.User | null, searchText: string, setRequests: React.Dispatch<React.SetStateAction<any[]>>, checkName: boolean = false) => {
    if (user) {
        const sanitizedEmail = user.email?.replace(/\./g, '_');
        const userRef = firebase.database().ref(`users/${sanitizedEmail}/informes`);
        let query = userRef.orderByChild('timestamp')
        if (!checkName) query = query.limitToLast(50)
        const snapshot = await query.once('value');
        const requestsData: Request[] = [];
        snapshot.forEach((childSnapshot) => {
            const req = { ...childSnapshot.val(), 'key': childSnapshot.key, 'preloaded': false };
            if (checkName && searchText && !req.fileName.toUpperCase().includes(searchText?.toUpperCase())) return;
            requestsData.unshift(req);
        });
        setRequests(requestsData);
    }
})

export const preloadPDF = async (req: Request, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!req) return
    const toastId = toast.info('Esperá mientras buscamos el PDF', { autoClose: false });
    setLoading(true)
    const appendedText = req.images ? '?img=' + req.images.join(',') : ''
    await axios.get('https://ecografias-veterinarias.glitch.me/getPdf/' + req.fileId + appendedText)
        .then((r) => { toast.success('¡Encontramos su archivo! Ábralo nuevamente si no puede visualizarlo'); req.preloaded = true })
        .catch((e) => toast.error('No pudimos encontrar su archivo. Intente de nuevo más tarde.'))
        .finally(() => setLoading(false))
    toast.dismiss(toastId);
}

