import { Google, LockOutlined } from "@mui/icons-material";
import { Box, Avatar, Typography, TextField, Button, Link, Grid, IconButton } from "@mui/material";
import { handleForgotPassword, handleGoogleLogin } from "../../scripts/login";

export default function LostPass({ setAuthScreen, mode }: { setAuthScreen: React.Dispatch<React.SetStateAction<string>>, mode?: string | null },) {
    return <Box
        sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
            Iniciar sesión
        </Typography>
        <Box component="form" onSubmit={(e) => handleSubmit(e)} noValidate sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Pedir cambio de contraseña
            </Button>
            <Grid container>
                <Grid item xs sx={{ alignSelf: 'center' }}>
                    <Link href="#" onClick={(e) => setAuthScreen('login')} variant='body2'>
                        Iniciar sesión
                    </Link>
                    <br />
                    {!['verifyEmail', 'recoverEmail'].includes(mode || '') &&
                        <Link href="#" onClick={(e) => setAuthScreen('register')} variant='body2'>
                            ¿No tienes cuenta? Registrarse
                        </Link>}
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={handleGoogleLogin}>
                        <Google />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    </Box>
}

const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')?.valueOf()
    if (typeof (email) === 'string') return handleForgotPassword(email)
};