import { toast } from 'react-toastify';
import firebase from '../firebase';

export const handlePassSignIn = async (email: string, password: string) => {
    if (!checkEmail(email)) return
    try {
        await firebase.auth().signInWithEmailAndPassword(email, password).then((userCredentials) => {
            if (userCredentials.user?.emailVerified || isActionURL()) {
                toast.success('¡Inicio de sesión exitoso!');
            } else {
                userCredentials.user?.sendEmailVerification()
            }
        });
    } catch (error) {
        console.error('Error al iniciar sesión:', error);
        toast.error('¡Error al iniciar sesión!');
    }
};

export const handleGoogleLogin = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
    googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');

    firebase.auth().signInWithPopup(googleProvider).then(() => {
        toast.success('¡Inicio de sesión exitoso!');
    }).catch()
}

export const handleRegister = async (email: string, password: string, name: string) => {
    if (!checkEmail(email)) return
    try {
        await firebase.auth().createUserWithEmailAndPassword(email, password).then((userCredentials) => {
            const userRef = firebase.database().ref(`users/${userCredentials.user?.email?.replace(/\./g, '_')}/name`);
            userRef.set(name);
            userCredentials.user?.sendEmailVerification()
        })
        toast.success('¡Registro exitoso!');
    } catch (error) {
        console.error('Error al registrar:', error);
        toast.error('¡Error al registrar!');
    }
};

export const handleForgotPassword = async (email: string) => {
    if (!checkEmail(email)) return
    try {
        await firebase.auth().sendPasswordResetEmail(email);
    } catch (error) {
        // console.error('Error al enviar el correo electrónico de restablecimiento de contraseña:',error);
        // toast.error('¡Error al enviar el correo electrónico de restablecimiento de contraseña!');
    } finally {
        toast.success('¡Correo electrónico de restablecimiento de contraseña enviado!');
    }
};

export const checkEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        console.error('Dirección de correo electrónico inválida');
        toast.error('Dirección de correo electrónico inválida');
    }
    return emailRegex.test(email)
}

export function isActionURL() {
    const searchParams = new URLSearchParams(document.location.search)
    const mode = searchParams.get('mode');
    const actionCode = searchParams.get('oobCode');

    return Boolean(mode && actionCode)
}
