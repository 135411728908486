import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from './firebase';
import logo from './logo.png';
import background from './paws.jpg';
import CssBaseline from '@mui/material/CssBaseline';

import {
  Avatar, Autocomplete, Button, TextField, Box, Typography, Container, AppBar,
  Toolbar, TableContainer, TablePagination, Table, TableHead, TableRow, TableCell, TableBody,
  Paper, Stepper, Step, StepLabel, List, ListItem, IconButton, ListItemAvatar, ListItemText,
  CircularProgress, Badge, ThemeProvider, createTheme
} from '@mui/material'
import { Delete, InsertPhoto, PictureAsPdf, CheckCircle, AccessTime, Logout } from '@mui/icons-material';

import { AuthScreen } from './components/AuthScreen';
import { formatBytes, toTitleCase, formatToLocalTime } from './scripts/helpers';
import { handleFileChange, handleSendingEmails } from './scripts/upload';
import { updateTableView, preloadPDF } from './scripts/viewer';
import { isActionURL, checkEmail } from './scripts/login';

interface Request {
  key: string;
  fileId: string;
  fileName: string;
  images: string[];
  emailSent: string[];
  timestamp: string;
  preloaded: boolean;
}

interface FileWithStatus extends File { uploaded: boolean }

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#a946c6',
      light: '#a946c6',
      dark: '#a946c6',
    },
    secondary: {
      main: '#a946c6',
      light: '#a946c6',
      dark: '#a946c6',
    },
    info: {
      main: '#a946c6',
      light: '#a946c6',
      dark: '#a946c6',
    },
  },
})

const noSelect = {
  'userSelect': 'none',
  'WebkitUserSelect': 'none',
  'MozUserSelect': 'none',
  'KhtmlUserSelect': 'none',
  'msUserSelect': 'none',
}

const App: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<FileWithStatus[]>([]);
  const [uploadStep, setUploadStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tablePage, setTablePage] = useState(0);
  const [fileName, setFileName] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchTime, setSearchTime] = useState<any>(null);
  const [emailToSend, setEmailToSend] = useState<string[]>([]);
  const [admin, setAdmin] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [botToken, setBotToken] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState<Request | null>(null)
  const [requests, setRequests] = useState<Request[]>([]);
  const [user, setUser] = useState<firebase.User | null>(null);
  const [click, setClicks] = useState<any>(null);

  const handleUpload = async () => {
    if (selectedFiles && chatId) {
      let photosArray = []
      let loadedDoc: FileWithStatus | null = null

      for (const file of selectedFiles) {
        if (file.size > 20 * 1024 * 1024) {
          console.warn('El archivo excede el tamaño máximo permitido.');
          toast.warn(`El archivo excede el tamaño máximo permitido de 20 MB. ${file.name}`);
          return setUploadStep(0)
        }
        if (file.name.endsWith('.pdf')) {
          if (loadedDoc) {
            console.warn('Demasiados archivos cargados.');
            toast.warn(`Se han cargado demasiados PDFs juntos, intente nuevamente de forma individual`);
            return setUploadStep(0)
          }
          loadedDoc = file
        } else {
          photosArray.push(file)
        }
      }

      if (loadedDoc === null) {
        console.warn('No existe el archivo PDF');
        toast.warn(`El archivo PDF no se ha encontrado. Intente nuevamente`);
        return setUploadStep(0)
      }

      let fileImgIds: string[] = []
      let responsesFiles = []
      const toastId = toast.info('Cargando archivos...', { autoClose: false });
      for (const file of photosArray) {
        const formData = new FormData();
        formData.append('chat_id', chatId);
        formData.append('document', file);
        formData.append('disable_notification', 'true');
        responsesFiles.push(axios.post(`https://api.telegram.org/bot${botToken}/sendDocument`, formData, { 'headers': { 'Content-Type': 'multipart/form-data' } })
          .then((response) => {
            file.uploaded = true
            fileImgIds.push(response.data.result.document.file_id)
            console.info('Archivo subido exitosamente:', file.name);
          })
          .catch((error) => {
            console.error('Error al subir el archivo:', error);
            toast.error(`¡Error al subir el archivo! ${file.name}`);
          }))
      }
      const formData = new FormData();
      formData.append('chat_id', chatId);
      formData.append('document', loadedDoc);
      formData.append('disable_notification', 'true');

      const documentUpload = axios.post(`https://api.telegram.org/bot${botToken}/sendDocument`, formData, { 'headers': { 'Content-Type': 'multipart/form-data' } })
        .then(async (response) => {
          if (loadedDoc === null) return response
          loadedDoc.uploaded = true
          console.info('Archivo subido exitosamente:', loadedDoc.name);
          return response
        })
        .catch((error) => {
          if (loadedDoc === null) { setUploadStep(0); throw error }
          console.error('Error al subir el archivo:', error);
          toast.error(`¡Error al subir el archivo! ${loadedDoc.name}`);
          setUploadStep(0)
          throw error
        });

      await Promise.all([...responsesFiles, documentUpload])
        .then(() => {
          toast.success('¡Archivos subidos exitosamente!');
        })
        .catch((error) => {
          console.error('Error al subir imágenes.', error);
          toast.error('¡Error al subir las imágenes! Intente nuevamente más tarde');
          setUploadStep(0)
          throw error
        })

      documentUpload.then(async (response) => {
        if (loadedDoc === null) throw Error('Document not found')
        if (user === null || user.email === null) throw Error('User not found')
        const fileId = response.data.result.document.file_id;
        const sanitizedUserEmail = user.email.replace(/\./g, '_');
        const userRef = firebase.database().ref(`users/${sanitizedUserEmail}/informes`);
        const pushRef = await userRef.push({
          'fileId': fileId,
          'fileName': fileName ? fileName : loadedDoc.name,
          'timestamp': firebase.database.ServerValue.TIMESTAMP,
          'images': fileImgIds
        });

        if (!pushRef.key) throw Error('No key')

        const req: Request = {
          key: pushRef.key,
          images: fileImgIds,
          fileId: fileId,
          fileName: fileName ? fileName : loadedDoc.name,
          emailSent: [],
          timestamp: (await pushRef.once('value')).child('timestamp').val(),
          preloaded: false
        }
        await preloadPDF(req, setLoading)
        setSelectedRequest(req)
        setUploadStep(2);
        updateTableView(user, searchText, setRequests,);
        toast.dismiss(toastId);
      })
    } else {
      console.warn('Por favor seleccione uno o más archivos para subir.');
      toast.warn('Por favor seleccione uno o más archivos para subir.');
    }
  };

  useEffect(() => {
    // Configurar el escucha de cambios de autenticación en Firebase
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      setUser(user);
      if (user?.emailVerified) {
        let userRef = firebase.database().ref(`users/${user.uid}`);
        userRef.once('value', (snapshot) => {
          const userData = snapshot.val();
          if (userData && userData.role === 'privileged') {
            setAdmin(true);
            setBotToken(userData.botToken)
            setChatId(userData.chatId)
            setApiKey(userData.apiKey)
          }
        }).catch((e) => { });
        const sanitizedEmail = user.email?.replace(/\./g, '_');
        userRef = firebase.database().ref(`users/${sanitizedEmail}/informes`);
        const snapshot = await userRef.orderByChild('timestamp').limitToLast(50).once('value');
        const requestsData: Request[] = [];
        snapshot.forEach((childSnapshot) => {
          const req = { ...childSnapshot.val(), 'key': childSnapshot.key };
          requestsData.unshift(req);
        });
        setRequests(requestsData);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleClick = async (req: Request, admin: boolean) => {
    if (!admin || (admin && click)) {
      if (click) {
        clearTimeout(click);
        setClicks(null);
      }
      const appendedText = req.images ? '?img=' + req.images.join(',') : ''
      if (!req.preloaded) await preloadPDF(req, setLoading)
      window.open('https://docs.google.com/viewer?url=https://ecografias-veterinarias.glitch.me/getPdf/' + req.fileId + appendedText, '_blank', 'popup')
      return
    }
    setClicks(setTimeout(() => {
      setClicks(null)
      setSelectedRequest(req)
      setUploadStep(2)
      setFileName(toTitleCase(req.fileName))
    }, 200))
  };

  const stepsInUpload = ['Seleccionar', 'Subir', 'Enviar']
  const handleStep = (direction: number) => {
    let updatedStep = 0
    if (direction > 0) {
      updatedStep = uploadStep + 1
      setUploadStep(uploadStep + 1)
    } else if (direction < 0) {
      updatedStep = uploadStep - 1
      setUploadStep(uploadStep - 1)
    } else {
      setUploadStep(0)
    }
    switch (updatedStep) {
      case 1: handleUpload(); break;
      case 2: updateTableView(user, searchText, setRequests,); break;
    }
  }

  const deleteFromList = (fileName: string) => {
    const filteredList = selectedFiles.filter((v) => v.name !== fileName)
    setSelectedFiles(filteredList)
  }

  const getStepContent = () => {
    switch (uploadStep) {
      case 0: return <Step1 />;
      case 1: return <ItemList />;
      case 2: return <Step3 />;
      default: setUploadStep(0); return <Step1 />;
    }
  }

  const ItemList = () => (
    <List sx={{ maxHeight: '50vh', overflowY: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
      {selectedFiles.map((file) => (
        <ListItem
          key={file.name}
          secondaryAction={
            uploadStep === 0 ?
              <IconButton edge="end" aria-label="delete" onClick={(e) => deleteFromList(file.name)}>
                <Delete />
              </IconButton>
              : uploadStep === 1 ? file.uploaded ? <CheckCircle /> : <AccessTime />
                : ''}
        >
          <ListItemAvatar>
            <Avatar sx={file.size > 20 * 1024 * 1024 ? { backgroundColor: '#d32f2f' } : {}}>
              {file.type.endsWith('pdf') ? <PictureAsPdf /> : <InsertPhoto />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={file.name}
            secondary={formatBytes(file.size, 2)}
          />
        </ListItem>
      )
      )}
    </List>
  )

  const Step1 = () => (
    <>
      <ItemList />
      <input
        accept=".jpg,.jpeg,.png,.pdf"
        style={{ display: 'none' }}
        onChange={(e) => handleFileChange(e, selectedFiles, setSelectedFiles)}
        id="fileInput"
        multiple
        type="file"
      />
      <label htmlFor="fileInput">
        <Button
          variant="contained"
          component="span"
          sx={{ mt: 3, ml: '5%', width: '90%', backgroundColor: '#a946c6' }}
        >
          Subir Archivos
        </Button>
      </label>
    </>
  )

  const Step3 = () => {
    const recentEmails: string[] = localStorage.getItem('recentEmails')?.split(';') || [];
    return <>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          variant="contained"
          component="span"
          sx={{ ml: '5%', width: '90%', backgroundColor: '#a946c6' }}
          disabled={loading}
          onClick={async (e) => {
            if (!selectedRequest) return
            if (!selectedRequest.preloaded) return preloadPDF(selectedRequest, setLoading)
            const appendedText = selectedRequest.images ? '?img=' + selectedRequest?.images.join(',') : ''
            window.open('https://docs.google.com/viewer?url=https://ecografias-veterinarias.glitch.me/getPdf/' + selectedRequest.fileId + appendedText, '_blank', 'popup')
          }}
        >
          Ver el PDF
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: '#a946c6',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
      <Autocomplete
        multiple
        autoSelect
        freeSolo
        filterSelectedOptions
        disableCloseOnSelect
        value={emailToSend}
        sx={{ mt: 3, mb: 0 }}
        renderInput={(params) => <TextField {...params} inputMode='email' label="Emails" />}
        onChange={(e, s, r) => {
          if (r === 'removeOption' || r === 'clear' || r === 'blur') return setEmailToSend(s)
          if (!checkEmail(s.at(-1) || '')) return
          setEmailToSend(s);
        }}
        options={recentEmails.filter((v) => !emailToSend.includes(v))}
      />
    </>
  }

  const visibleRows = React.useMemo(
    () => requests.slice(
      tablePage * 5,
      tablePage * 5 + 5,
    ),
    [tablePage, requests],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const resetPage = React.useMemo(
    () => setTablePage(0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requests],
  );

  const handleSignOut = () => {
    firebase.auth().signOut();
    setSelectedFiles([]);
    setSelectedRequest(null);
    setAdmin(false);
    setEmailToSend([]);
    setFileName('');
    setChatId(null);
    setBotToken(null);
    setApiKey(null);
    setRequests([]);
    setUser(null);
    setUploadStep(0)
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div style={{ position: 'absolute', backgroundImage: `linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.8)),url(${background})`, width: '100vw', height: '100vh', zIndex: '-1000', }} />
      <AppBar position="relative" sx={noSelect}>
        <Toolbar sx={{ pr: '24px', backgroundColor: '#a946c6' }}>
          <Avatar src={logo} sx={{ mr: 2 }} />
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" color="inherit" noWrap>
              Ecografías Veterinarias
            </Typography>
            <Typography variant="subtitle2" color="inherit" noWrap>
              M.V. Sofia Violintzis
            </Typography>
          </Box>
          {user &&
            <IconButton edge="end" aria-label="delete" onClick={handleSignOut}>
              <Logout sx={{ color: '#ffffff' }} />
            </IconButton>}
        </Toolbar>
      </AppBar>
      {
        user?.emailVerified && admin &&
        <Container component="main" maxWidth="sm" sx={noSelect}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 4 }, p: { xs: 2, md: 3 }, pt: { xs: 0, md: 0 } }}>
            <Typography variant="body2" sx={{ p: { xs: 0.5, md: 1 }, pr: { xs: 0, md: 0 }, display: 'flex', justifyContent: 'flex-end', color: 'gray' }}>{process.env.REACT_APP_GIT_HASH && 'v. ' + process.env.REACT_APP_GIT_HASH}<br /></Typography>
            <TextField
              id="fileName"
              label="Nombre del Paciente"
              value={fileName} required
              onChange={(e) => { setFileName(e.target.value) }}
              sx={{ width: '80%', ml: '10%' }}
              disabled={uploadStep !== 0}
              error={selectedFiles.length > 0 && !fileName}
            />
            <Stepper activeStep={uploadStep} sx={{ pt: 3, pb: 5, '& .MuiStepIcon-root.Mui-active': { color: '#a946c6' } }}>
              {stepsInUpload.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {getStepContent()}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {uploadStep === 2 && (
                <Button onClick={(e) => { setFileName(''); setSelectedRequest(null); setSelectedFiles([]); setEmailToSend([]); handleStep(0) }} sx={{ mt: 3, ml: 1 }}>
                  Volver
                </Button>
              )}
              {uploadStep === 2 ?
                <Box sx={{ position: 'relative' }}>
                  <Button
                    variant="contained"
                    onClick={(e) => { handleSendingEmails(user, selectedRequest, emailToSend, apiKey, setLoading, setEmailToSend); updateTableView(user, searchText, setRequests) }}
                    sx={{ mt: 3, ml: 1 }}
                    disabled={!selectedRequest || emailToSend.length === 0 || loading}
                  >
                    Enviar emails
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: '#a946c6',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                      }}
                    />
                  )}
                </Box>
                : uploadStep === 0 &&
                <Button
                  variant="contained"
                  onClick={(e) => handleStep(1)}
                  sx={{ mt: 3, ml: 1 }}
                  disabled={(selectedFiles.length > 0 && !fileName) || selectedFiles.length === 0}
                >
                  Subir a Telegram
                </Button>
              }
            </Box>
          </Paper>
        </Container>
      }
      {
        !user?.emailVerified || isActionURL() ?
          <AuthScreen user={user} />
          : !selectedRequest && selectedFiles.length === 0 &&
          <Container maxWidth="lg" sx={{ mt: 4, ...noSelect }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <React.Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography component="h2" variant="h6" color="primary" gutterBottom sx={{ flexGrow: 1 }}>
                    Ecografías Cargadas
                  </Typography>
                  <TextField
                    name="patientName"
                    id="patientName"
                    label="Paciente"
                    value={searchText}
                    onBlur={(e) => { updateTableView(user, searchText, setRequests, true); if (searchTime) { clearTimeout(searchTime) }; setSearchTime(null); }}
                    onChange={(e) => { setSearchText(e.target.value) }}//;if(searchTime){clearTimeout(searchTime)};setSearchTime(setTimeout(()=>{handleSearch();setSearchTime(null)},2000))}}
                    onKeyUp={(e) => { if (searchTime) { clearTimeout(searchTime) }; setSearchTime(setTimeout(() => { updateTableView(user, searchText, setRequests, true); setSearchTime(null) }, 1000)) }}
                  />
                </Box>
                <TableContainer>
                  <Table size="medium" sx={{ position: 'relative' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">Fecha de Subida</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visibleRows.map((req) => (
                        <TableRow key={`${req.key}-${req.fileId}`} style={{ cursor: 'pointer' }} onClick={async (e) => { if (!loading) await handleClick(req, admin) }}>
                          <TableCell style={req.emailSent !== undefined && admin ? { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } : {}}>
                            <Badge color="secondary" badgeContent=" " variant='dot' invisible={Number(req.timestamp) + 4 * 24 * 60 * 60 * 1000 <= new Date().getTime()}>
                              {toTitleCase(req.fileName)}
                            </Badge>
                            {req.emailSent !== undefined && admin && <span style={{ color: '#aaaaaa', fontSize: '12px' }}>Enviado a {req.emailSent.join(', ')}</span>}
                          </TableCell>
                          <TableCell align="right">{formatToLocalTime(req.timestamp, admin)}</TableCell>
                        </TableRow>
                      ))}
                      {Math.max(0, (1 + tablePage) * 5 - requests.length) > 0 && (
                        <TableRow key={'completeTableSpace'}
                          style={{
                            height: 53 * Math.max(0, (1 + tablePage) * 5 - requests.length),
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {loading && (
                      <CircularProgress
                        size={36}
                        sx={{
                          color: '#a946c6',
                          position: 'absolute',
                          bottom: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={requests.length}
                  rowsPerPage={5}
                  page={tablePage}
                  onPageChange={(e, page) => setTablePage(page)}
                />
              </React.Fragment>
            </Paper>
          </Container>
      }
      <ToastContainer autoClose={3000} hideProgressBar />
    </ThemeProvider >
  );
};

export default App;
