import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROYECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

const app = firebase.initializeApp(firebaseConfig);

if (process.env.REACT_APP_GIT_HASH) console.log("Git Hash:", process.env.REACT_APP_GIT_HASH)

if (process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN === 'true') window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
else window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_KEY),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

export default firebase;
