import { LockOutlined } from "@mui/icons-material";
import { Box, Avatar, Typography, Grid, TextField, Button } from "@mui/material";
import firebase from "../../firebase";
import { useState } from "react";

export function ResetPass({ actionCode }: { actionCode: string }) {
    const [password, setPassword] = useState('login');
    const [passwordRepeat, setPasswordRepeat] = useState('login');

    return <Box
        sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
            Reiniciar Contraseña
        </Typography>
        <Box component="form" noValidate onSubmit={(e) => handleSubmit(e, actionCode)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                        autoComplete="new-password"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        name="passwordRepeat"
                        label="Repetir Contraseña"
                        type="password"
                        id="passwordRepeat"
                        value={passwordRepeat}
                        onChange={(e) => { setPasswordRepeat(e.target.value) }}
                        autoComplete="new-password"
                        error={password !== passwordRepeat}
                    />
                </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={password !== passwordRepeat || password === ''}
            >
                Cambiar contraseña
            </Button>
        </Box>
    </Box>
}

const handleSubmit = (event: React.FormEvent<HTMLFormElement>, actionCode: string) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const newPassword = data.get('password')?.valueOf()
    if (typeof (newPassword) === 'string' && newPassword === data.get('passwordRepeat')?.valueOf()) firebase.auth().confirmPasswordReset(actionCode, newPassword).then(() => window.location.assign(window.location.origin))
};