import { toast } from "react-toastify";
import firebase from '../firebase';
import axios from "axios";
import { toTitleCase } from "./helpers";


interface FileWithStatus extends File { uploaded: boolean }

export const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, selectedFiles: FileWithStatus[], setSelectedFiles: React.Dispatch<React.SetStateAction<FileWithStatus[]>>) => {
    if (!e.target.files) return

    const filesToAdd: FileWithStatus[] = Array.from(e.target.files).filter((v) => selectedFiles.every((vi) => vi.name !== v.name)).map((v) => Object.assign(v, { uploaded: false }))
    setSelectedFiles(selectedFiles.concat(filesToAdd));
};

export const handleSendingEmails = async (user: firebase.User, selectedRequest: any, emailToSend: string[], apiKey: null, setLoading: React.Dispatch<React.SetStateAction<boolean>>, setEmailToSend: React.Dispatch<React.SetStateAction<string[]>>) => {
    const toastId = toast.info('Enviando el email...', { autoClose: false });
    setLoading(true)
    if (!selectedRequest) return toast.error('Seleccione un archivo primero');
    for (const email of emailToSend) {
        if (user?.email === email) continue
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            console.error('Dirección de correo electrónico inválida');
            toast.error('Dirección de correo electrónico inválida');
            return;
        }

        const sanitizedEmail = email.replace(/\./g, '_');
        const userRef = firebase.database().ref(`users/${sanitizedEmail}/informes`);
        userRef.push({
            'fileId': selectedRequest.fileId,
            'fileName': selectedRequest.fileName ? toTitleCase(selectedRequest.fileName) : 'Ecografía Veterinaria',
            'images': selectedRequest.images ? selectedRequest.images : [],
            'timestamp': selectedRequest.timestamp
        });

        await axios.post(`https://ecografias-veterinarias.glitch.me/sendMail`, {
            'fileId': selectedRequest.fileId,
            'fileName': `${selectedRequest.fileName ? toTitleCase(selectedRequest.fileName) + ' - ' : ''}Informe Ecográfico Veterinario.pdf`,
            'userEmail': emailToSend,
            'asunto': `${selectedRequest.fileName ? toTitleCase(selectedRequest.fileName) : 'Sofía Violintzis'} - Informe Ecografía Veterinaria`,
            'message': 'Buenos días, le envío el informe sobre la ecografía veterinaria realizada.\n\nM.V. Sofía Violintzis',
            'images': selectedRequest.images
        }, { 'headers': { 'x-api-key': apiKey } })
            .then((r) => toast.success(`¡Email enviado exitosamente!`))
            .catch((e) => toast.error('Email no se pudo enviar correctamente'));
    }

    const userRef = firebase.database().ref(`users/${user?.email?.replace(/\./g, '_')}/informes/${selectedRequest.key}/emailSent`);
    const emailSent = await userRef.once('value').then((sn) => sn.val())
    userRef.set(emailSent ? [...emailSent, ...emailToSend] : emailToSend);

    let recentEmails: string[] = localStorage.getItem('recentEmails')?.split(';') || [];
    recentEmails = recentEmails.filter((v) => !emailToSend.includes(v))
    recentEmails = [...emailToSend, ...recentEmails]

    for (let i = Math.floor(recentEmails.length / 3); i >= 0; i--) {
        let tempSlice = recentEmails.slice(0, i !== 0 ? -i : recentEmails.length)
        try {
            localStorage.setItem('recentEmails', tempSlice.join(';'))
        } catch (err) {
            tempSlice = tempSlice.slice(0, -1)
            localStorage.setItem('recentEmails', tempSlice.join(';'))
            break;
        }
    }

    toast.dismiss(toastId);
    setLoading(false)
    setEmailToSend([]);
};